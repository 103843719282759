exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-career-jsx": () => import("./../../../src/pages/career.jsx" /* webpackChunkName: "component---src-pages-career-jsx" */),
  "component---src-pages-case-studies-custom-chatbot-develeopment-jsx": () => import("./../../../src/pages/case-studies/custom-chatbot-develeopment.jsx" /* webpackChunkName: "component---src-pages-case-studies-custom-chatbot-develeopment-jsx" */),
  "component---src-pages-case-studies-diamond-marketplace-development-jsx": () => import("./../../../src/pages/case-studies/diamond-marketplace-development.jsx" /* webpackChunkName: "component---src-pages-case-studies-diamond-marketplace-development-jsx" */),
  "component---src-pages-case-studies-digital-clinical-trial-platform-jsx": () => import("./../../../src/pages/case-studies/digital-clinical-trial-platform.jsx" /* webpackChunkName: "component---src-pages-case-studies-digital-clinical-trial-platform-jsx" */),
  "component---src-pages-case-studies-jsx": () => import("./../../../src/pages/case-studies.jsx" /* webpackChunkName: "component---src-pages-case-studies-jsx" */),
  "component---src-pages-case-studies-vaccine-healthcare-development-jsx": () => import("./../../../src/pages/case-studies/vaccine-healthcare-development.jsx" /* webpackChunkName: "component---src-pages-case-studies-vaccine-healthcare-development-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-dev-404-page-jsx": () => import("./../../../src/pages/dev-404-page.jsx" /* webpackChunkName: "component---src-pages-dev-404-page-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-open-source-jsx": () => import("./../../../src/pages/open-source.jsx" /* webpackChunkName: "component---src-pages-open-source-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-products-ai-tutor-jsx": () => import("./../../../src/pages/products/ai-tutor.jsx" /* webpackChunkName: "component---src-pages-products-ai-tutor-jsx" */),
  "component---src-pages-products-jsx": () => import("./../../../src/pages/products.jsx" /* webpackChunkName: "component---src-pages-products-jsx" */),
  "component---src-pages-products-laravel-classified-ads-management-system-jsx": () => import("./../../../src/pages/products/laravel-classified-ads-management-system.jsx" /* webpackChunkName: "component---src-pages-products-laravel-classified-ads-management-system-jsx" */),
  "component---src-pages-products-laravel-clinic-appointment-booking-system-jsx": () => import("./../../../src/pages/products/laravel-clinic-appointment-booking-system.jsx" /* webpackChunkName: "component---src-pages-products-laravel-clinic-appointment-booking-system-jsx" */),
  "component---src-pages-products-laravel-crm-script-project-management-jsx": () => import("./../../../src/pages/products/laravel-crm-script-project-management.jsx" /* webpackChunkName: "component---src-pages-products-laravel-crm-script-project-management-jsx" */),
  "component---src-pages-products-laravel-crowdfunding-application-jsx": () => import("./../../../src/pages/products/laravel-crowdfunding-application.jsx" /* webpackChunkName: "component---src-pages-products-laravel-crowdfunding-application-jsx" */),
  "component---src-pages-products-laravel-hospital-management-system-jsx": () => import("./../../../src/pages/products/laravel-hospital-management-system.jsx" /* webpackChunkName: "component---src-pages-products-laravel-hospital-management-system-jsx" */),
  "component---src-pages-products-laravel-invoice-management-system-jsx": () => import("./../../../src/pages/products/laravel-invoice-management-system.jsx" /* webpackChunkName: "component---src-pages-products-laravel-invoice-management-system-jsx" */),
  "component---src-pages-products-laravel-job-portal-scripts-jsx": () => import("./../../../src/pages/products/laravel-job-portal-scripts.jsx" /* webpackChunkName: "component---src-pages-products-laravel-job-portal-scripts-jsx" */),
  "component---src-pages-products-laravel-multi-hospital-management-system-jsx": () => import("./../../../src/pages/products/laravel-multi-hospital-management-system.jsx" /* webpackChunkName: "component---src-pages-products-laravel-multi-hospital-management-system-jsx" */),
  "component---src-pages-products-laravel-news-management-system-jsx": () => import("./../../../src/pages/products/laravel-news-management-system.jsx" /* webpackChunkName: "component---src-pages-products-laravel-news-management-system-jsx" */),
  "component---src-pages-products-laravel-online-appintment-system-jsx": () => import("./../../../src/pages/products/laravel-online-appintment-system.jsx" /* webpackChunkName: "component---src-pages-products-laravel-online-appintment-system-jsx" */),
  "component---src-pages-products-laravel-pos-management-jsx": () => import("./../../../src/pages/products/laravel-pos-management.jsx" /* webpackChunkName: "component---src-pages-products-laravel-pos-management-jsx" */),
  "component---src-pages-products-laravel-project-management-system-jsx": () => import("./../../../src/pages/products/laravel-project-management-system.jsx" /* webpackChunkName: "component---src-pages-products-laravel-project-management-system-jsx" */),
  "component---src-pages-products-laravel-reactjs-library-management-system-jsx": () => import("./../../../src/pages/products/laravel-reactjs-library-management-system.jsx" /* webpackChunkName: "component---src-pages-products-laravel-reactjs-library-management-system-jsx" */),
  "component---src-pages-products-laravel-saas-invoice-management-system-jsx": () => import("./../../../src/pages/products/laravel-saas-invoice-management-system.jsx" /* webpackChunkName: "component---src-pages-products-laravel-saas-invoice-management-system-jsx" */),
  "component---src-pages-products-laravel-saas-portfolio-management-jsx": () => import("./../../../src/pages/products/laravel-saas-portfolio-management.jsx" /* webpackChunkName: "component---src-pages-products-laravel-saas-portfolio-management-jsx" */),
  "component---src-pages-products-laravel-saas-portfolio-management-system-jsx": () => import("./../../../src/pages/products/laravel-saas-portfolio-management-system.jsx" /* webpackChunkName: "component---src-pages-products-laravel-saas-portfolio-management-system-jsx" */),
  "component---src-pages-products-laravel-sports-betting-system-jsx": () => import("./../../../src/pages/products/laravel-sports-betting-system.jsx" /* webpackChunkName: "component---src-pages-products-laravel-sports-betting-system-jsx" */),
  "component---src-pages-products-laravel-support-ticket-system-jsx": () => import("./../../../src/pages/products/laravel-support-ticket-system.jsx" /* webpackChunkName: "component---src-pages-products-laravel-support-ticket-system-jsx" */),
  "component---src-pages-products-laravel-vcards-saas-management-system-jsx": () => import("./../../../src/pages/products/laravel-vcards-saas-management-system.jsx" /* webpackChunkName: "component---src-pages-products-laravel-vcards-saas-management-system-jsx" */),
  "component---src-pages-products-link-tracker-jsx": () => import("./../../../src/pages/products/link-tracker.jsx" /* webpackChunkName: "component---src-pages-products-link-tracker-jsx" */),
  "component---src-pages-products-realtime-chat-app-script-laravel-jsx": () => import("./../../../src/pages/products/realtime-chat-app-script-laravel.jsx" /* webpackChunkName: "component---src-pages-products-realtime-chat-app-script-laravel-jsx" */),
  "component---src-pages-products-restaurant-management-jsx": () => import("./../../../src/pages/products/restaurant-management.jsx" /* webpackChunkName: "component---src-pages-products-restaurant-management-jsx" */),
  "component---src-pages-products-website-ai-chatbot-jsx": () => import("./../../../src/pages/products/website-ai-chatbot.jsx" /* webpackChunkName: "component---src-pages-products-website-ai-chatbot-jsx" */),
  "component---src-pages-return-and-refund-policy-jsx": () => import("./../../../src/pages/return-and-refund-policy.jsx" /* webpackChunkName: "component---src-pages-return-and-refund-policy-jsx" */),
  "component---src-pages-services-chatbot-development-services-jsx": () => import("./../../../src/pages/services/chatbot-development-services.jsx" /* webpackChunkName: "component---src-pages-services-chatbot-development-services-jsx" */),
  "component---src-pages-services-generative-ai-development-services-jsx": () => import("./../../../src/pages/services/generative-ai-development-services.jsx" /* webpackChunkName: "component---src-pages-services-generative-ai-development-services-jsx" */),
  "component---src-pages-services-hire-android-developers-jsx": () => import("./../../../src/pages/services/hire-android-developers.jsx" /* webpackChunkName: "component---src-pages-services-hire-android-developers-jsx" */),
  "component---src-pages-services-hire-chatbot-developers-jsx": () => import("./../../../src/pages/services/hire-chatbot-developers.jsx" /* webpackChunkName: "component---src-pages-services-hire-chatbot-developers-jsx" */),
  "component---src-pages-services-hire-flutter-developers-jsx": () => import("./../../../src/pages/services/hire-flutter-developers.jsx" /* webpackChunkName: "component---src-pages-services-hire-flutter-developers-jsx" */),
  "component---src-pages-services-hire-generative-ai-developers-jsx": () => import("./../../../src/pages/services/hire-generative-ai-developers.jsx" /* webpackChunkName: "component---src-pages-services-hire-generative-ai-developers-jsx" */),
  "component---src-pages-services-hire-laravel-developers-jsx": () => import("./../../../src/pages/services/hire-laravel-developers.jsx" /* webpackChunkName: "component---src-pages-services-hire-laravel-developers-jsx" */),
  "component---src-pages-services-hire-nodejs-developers-jsx": () => import("./../../../src/pages/services/hire-nodejs-developers.jsx" /* webpackChunkName: "component---src-pages-services-hire-nodejs-developers-jsx" */),
  "component---src-pages-services-hire-reactjs-developers-jsx": () => import("./../../../src/pages/services/hire-reactjs-developers.jsx" /* webpackChunkName: "component---src-pages-services-hire-reactjs-developers-jsx" */),
  "component---src-pages-services-laravel-development-services-jsx": () => import("./../../../src/pages/services/laravel-development-services.jsx" /* webpackChunkName: "component---src-pages-services-laravel-development-services-jsx" */),
  "component---src-pages-services-mobile-app-development-services-jsx": () => import("./../../../src/pages/services/mobile-app-development-services.jsx" /* webpackChunkName: "component---src-pages-services-mobile-app-development-services-jsx" */),
  "component---src-pages-services-node-js-development-services-jsx": () => import("./../../../src/pages/services/node-js-development-services.jsx" /* webpackChunkName: "component---src-pages-services-node-js-development-services-jsx" */),
  "component---src-pages-services-react-js-development-services-jsx": () => import("./../../../src/pages/services/react-js-development-services.jsx" /* webpackChunkName: "component---src-pages-services-react-js-development-services-jsx" */),
  "component---src-pages-terms-of-service-jsx": () => import("./../../../src/pages/terms-of-service.jsx" /* webpackChunkName: "component---src-pages-terms-of-service-jsx" */),
  "component---src-template-redirect-page-js": () => import("./../../../src/template/redirect-page.js" /* webpackChunkName: "component---src-template-redirect-page-js" */)
}

